import { Box, Grid } from "@mantine/core";
import React, { Fragment, memo } from "react";
import { MTypography } from "../../../ui";
import { Link } from "react-router-dom";
import classes from "./FBottom.module.scss";
import { footerLink } from "../../../constant/Constant";
import { FiFacebook, FiYoutube } from "react-icons/fi";
import { FaInstagram } from "react-icons/fa";
import { RiTwitterXFill } from "react-icons/ri";
import { COLORS } from "../../../assets";

const FBottom = () => {
  return (
    <Grid mt={20}>
      <Grid.Col span={{ base: 6, xs: 6, sm: 3, md: 3, lg: 3 }}>
        <Box>
          <MTypography
            variant="description_18"
            tag="h3"
            marginBottom={8}
            color="white"
          >
            Quick Links
          </MTypography>
          <Fragment>
            {footerLink.quickLink.map((item) => (
              <Link key={item.label} to={item.link} className={classes.link}>
                {item?.label}
              </Link>
            ))}
          </Fragment>
        </Box>
      </Grid.Col>
      <Grid.Col span={{ base: 6, xs: 6, sm: 3, md: 3, lg: 3 }}>
        <Box>
          <MTypography
            variant="description_18"
            tag="h3"
            marginBottom={8}
            color="white"
          >
            Policy
          </MTypography>

          <Fragment>
            {footerLink.policyLink.map((item) => (
              <Link key={item.label} to={item.link} className={classes.link}>
                {item?.label}
              </Link>
            ))}
          </Fragment>
        </Box>
      </Grid.Col>
      {/* <Grid.Col span={{ base: 6, xs: 3, sm: 3, md: 3, lg: 3 }}>
        <Box>
          <MTypography
            variant="description_18"
            tag="h3"
            marginBottom={8}
            color="white"
          >
            Company
          </MTypography>
          <Fragment>
            {footerLink.companyLink.map((item) => (
              <Link key={item.label} to={item.link} className={classes.link}>
                {item?.label}
              </Link>
            ))}
          </Fragment>
        </Box>
      </Grid.Col> */}
      <Grid.Col span={{ base: 6, xs: 6, sm: 3, md: 3, lg: 3 }}>
        <Box>
          <MTypography
            variant="description_18"
            tag="h3"
            marginBottom={8}
            color="white"
          >
            Support
          </MTypography>
          <Fragment>
            {footerLink.supportLink.map((item) => (
              <Link key={item.label} to={item.link} className={classes.link}>
                {item?.label}
              </Link>
            ))}
          </Fragment>
        </Box>
      </Grid.Col>
      <Grid.Col span={{ base: 6, xs: 6, sm: 3, md: 3, lg: 3 }}>
        <Box>
          <MTypography
            variant="description_18"
            tag="h3"
            marginBottom={8}
            color="white"
          >
            Follow US
          </MTypography>
          <Box className={classes.socialMediaContainer}>
            <Box className={classes.socialMediaBox}>
              <a href="https://www.facebook.com/people/Benylo/61559059413589/">
                <FiFacebook color={COLORS.white} />
              </a>
            </Box>
            <Box className={classes.socialMediaBox}>
              <a href="https://x.com/i/flow/login?redirect_after_login=%2Fbenylo929">
                <RiTwitterXFill color={COLORS.white} />
              </a>
            </Box>

            <Box className={classes.socialMediaBox}>
              <a href="https://youtube.com/@_benylo_?si=tEQxfIlsA0qzygWU">
                <FiYoutube color={COLORS.white} />
              </a>
            </Box>

            <Box className={classes.socialMediaBox}>
              <a href="https://www.instagram.com/_benylo_/?igsh=MWl6bHJwM3h2em95Mg%3D%3D">
                <FaInstagram color={COLORS.white} />
              </a>
            </Box>
          </Box>
        </Box>
      </Grid.Col>
    </Grid>
  );
};

export default memo(FBottom);
